import Button from '../../button';
import { AnimatedTooltip } from '../../ui/animated-tooltip';

const HeroSection = () => {
  return (
    <div className="mt-8 md:mt-32 flex gap-16 items-center">
      <div className="flex flex-col gap-6 pb-12">
        <div className="flex flex-col gap-4">
          <div className="flex items-center bg-brandSecondary gap-2 p-1 px-2 rounded-md w-fit">
            <div className="h-[6px] w-[6px] rounded-full bg-brand"></div>
            <div className="text-sm text-brand font-medium">
              Open for side projects
            </div>
          </div>
          <div className="font-medium text-3xl md:text-4xl !leading-normal">
            Hi, I'm Saifeeee
            <br />
            Product Designer & UI UX Developer in Berlin.
          </div>
          <img
            src="./cover-photo-test.webp"
            alt="Cover"
            className="w-full h-64 object-cover rounded-2xl block md:hidden"
          />
        </div>
        <div className="text-base text-blackSecondary">
          Product Designer based in Germany, specializing in User interfaces and
          User experiences. Since 2018, I've enjoyed working in agencies,
          companies, startups, and real estate, leading projects used by
          thousands of people. Got opportunity to be working in four different
          countries, i.e. Pakistan, UAE, USA, Germany.
        </div>
        <div className="flex flex-col md:flex-row gap-2 md:gap-0 items-start md:items-center -mt-2">
          <div className="flex items-center">
            <AnimatedTooltip />
          </div>
          <div className="flex flex-col gap-0.5 md:ml-5">
            <div className="flex items-center gap-2">
              <div className="flex items-center">
                <svg
                  className="w-6 h-6 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  className="w-6 h-6 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  className="w-6 h-6 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  className="w-6 h-6 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  className="w-6 h-6 ms-1 text-yellow-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
              </div>
              <p className="text-blackSecondary text-lg font-semibold">5.0</p>
            </div>
            <p className="text-blackSecondary/70 text-md ml-2">
              From 40+ projects
            </p>
          </div>
        </div>
        <Button label="About Me" />
      </div>
      <img
        src="./cover-photo.webp"
        alt="Cover"
        className="w-80 rounded-3xl hidden md:block"
      />
    </div>
  );
};

export default HeroSection;
