'use client';
import React, { useState } from 'react';
import {
  motion,
  useTransform,
  AnimatePresence,
  useMotionValue,
  useSpring,
} from 'framer-motion';

export const AnimatedTooltip = ({
  items = [
    {
      id: 1,
      name: 'Brenden Shue',
      designation: 'Strategic Partnerships @Kukun',
      image: './reviewers/reviewer-1.webp',
    },
    {
      id: 2,
      name: 'Christopher Mathew',
      designation: 'Client @Devigners',
      image: './reviewers/reviewer-5.webp',
    },
    {
      id: 3,
      name: 'Aiman Tahir',
      designation: 'Data Engineer @Devigners',
      image: './reviewers/reviewer-3.webp',
    },
    {
      id: 4,
      name: 'Nusrate Ibrahim',
      designation: 'Head of Research @ESGLibrary',
      image: './reviewers/reviewer-2.webp',
    },
    {
      id: 5,
      name: 'Hissaan Shah',
      designation: 'Azure Data Scientist @Germany',
      image: './reviewers/reviewer-4.webp',
    },
    {
      id: 6,
      name: 'Kishen Patel',
      designation: 'Client @Fiverr',
      image: './reviewers/reviewer-6.webp',
    },
  ],
}: {
  items?: {
    id: number;
    name: string;
    designation: string;
    image: string;
  }[];
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const springConfig = { stiffness: 100, damping: 5 };
  const x = useMotionValue(0); // going to set this value on mouse move
  // rotate the tooltip
  const rotate = useSpring(
    useTransform(x, [-100, 100], [-45, 45]),
    springConfig
  );
  // translate the tooltip
  const translateX = useSpring(
    useTransform(x, [-100, 100], [-50, 50]),
    springConfig
  );
  const handleMouseMove = (event: any) => {
    const halfWidth = event.target.offsetWidth / 2;
    x.set(event.nativeEvent.offsetX - halfWidth); // set the x value, which is then used in transform and rotate
  };

  return (
    <>
      {items.map((item, idx) => (
        <div
          className="-mr-4  relative group"
          key={item.name}
          onMouseEnter={() => setHoveredIndex(item.id)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <AnimatePresence mode="popLayout">
            {hoveredIndex === item.id && (
              <motion.div
                initial={{ opacity: 0, y: 20, scale: 0.6 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  scale: 1,
                  transition: {
                    type: 'spring',
                    stiffness: 260,
                    damping: 10,
                  },
                }}
                exit={{ opacity: 0, y: 20, scale: 0.6 }}
                style={{
                  translateX: translateX,
                  rotate: rotate,
                  whiteSpace: 'nowrap',
                }}
                className="absolute -top-16 -left-1/2 translate-x-1/2 flex text-xs  flex-col items-center justify-center rounded-md bg-black z-50 shadow-xl px-4 py-2"
              >
                <div className="absolute inset-x-10 z-30 w-[20%] -bottom-px bg-gradient-to-r from-transparent via-brand to-transparent h-px " />
                <div className="absolute left-10 w-[40%] z-30 -bottom-px bg-gradient-to-r from-transparent via-brand to-transparent h-px " />
                <div className="font-bold text-white relative z-30 text-base">
                  {item.name}
                </div>
                <div className="text-white text-xs">{item.designation}</div>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="group-hover:z-30 border-[3px] border-white rounded-full overflow-hidden hover:scale-105 relative transition duration-500">
            <img
              onMouseMove={handleMouseMove}
              height={100}
              width={100}
              src={item.image}
              alt={item.name}
              className="object-contain object-center h-14 w-14 scale-105 group-hover:z-30 relative transition duration-500"
            />
          </div>
        </div>
      ))}
    </>
  );
};
