import React from 'react';
import Button from '../../button';
import HeaderItem from '../../header/header-item';
import SparklesText from '../../magicui/sparkles-text';

const Footer = () => {
  return (
    <>
      <div className="flex flex-col md:flex-row gap-0 md:gap-16 justify-between items-center py-4 mb-4 md:py-16">
        <div className="order-2 md:order-1 w-full flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <div className="text-sm text-brand font-medium">Get in Touch</div>
              <div className="font-medium text-4xl leading-normal">
                Got an interesting mission?
              </div>
            </div>
            <div className="text-base text-blackSecondary">
              Let's connect to make an impact together! How about grabbing a
              coffee?
            </div>
          </div>
          <Button
            label="Connect with Saifeee"
            isScroll={false}
            className="px-8"
          />
        </div>
        <div className="order-1 md:order-2 w-full flex items-center justify-center">
          <video
            src="./contact-us.mp4"
            controls={false}
            loop={true}
            autoPlay={true}
            className="w-[400px] md:w-[800px] h-auto flex-none"
            muted
          />
        </div>
      </div>
      <div className="border-t md:border-t-0 md:border-b pt-10 md:pt-0 md:pb-8 mb-6 md:mb-16 flex flex-col gap-4 md:flex-row items-start justify-center md:justify-between">
        <div className="text-blackSecondary text-center md:text-left w-full">
          © Saif Ullah Bin Khaki 2024
        </div>
        <div className="w-full hidden md:flex gap-4 md:gap-8 justify-center md:justify-end">
          <HeaderItem
            href="https://linkedin.com/in/saifullahbinkhaki"
            label="LinkedIn"
          />
          <HeaderItem
            href="https://dribbble.com/saifullahbinkhaki"
            label="Dribbble"
          />
          <HeaderItem href="mailto:saifbinkhaki@gmail.com" label="Contact" />
          <a
            href={'https://cal.com/saifbinkhaki/quick-chat-20-minutes'}
            target="_blank"
            rel="noreferrer"
            className="font-medium text-blackSecondary hover:text-brand brand-transition"
          >
            <SparklesText
              text="Schedule a Call"
              sparklesCount={2}
              className="text-base font-medium text-blackSecondary hover:text-brand brand-transition"
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
