import React from 'react';
import HeaderItem from './header-item';
import SparklesText from '../magicui/sparkles-text';

const Header: React.FC = () => {
  return (
    <div className="hidden md:flex  items-center justify-between h-20 border-b">
      <div className="flex gap-2 md:gap-3 items-center">
        <img src="./profile-photo.webp" alt="Profile" className="w-10 h-10" />
        <span className="text-xl font-medium hidden sm:block">
          Saif Ullah Bin Khaki
        </span>
      </div>
      <div className="flex gap-5 md:gap-8">
        <HeaderItem
          href="https://linkedin.com/in/saifullahbinkhaki"
          label="LinkedIn"
        />
        <HeaderItem
          href="https://dribbble.com/saifullahbinkhaki"
          label="Dribbble"
        />
        <HeaderItem href="mailto:saifbinkhaki@gmail.com" label="Contact" />
        <a
          href={'https://cal.com/saifbinkhaki/quick-chat-20-minutes'}
          target="_blank"
          rel="noreferrer"
          className="font-medium text-blackSecondary hover:text-brand brand-transition"
        >
          <SparklesText
            text="Schedule a Call"
            sparklesCount={2}
            className="text-base font-medium text-blackSecondary hover:text-brand brand-transition"
          />
        </a>
      </div>
    </div>
  );
};

export default Header;
