import React from 'react';
import Header from '../components/header';
import HeroSection from '../components/sections/heroSection';
import CompanySection from '../components/sections/companySection';
import PortfolioSection from '../components/sections/portfolioSection';
import ProfileSection from '../components/sections/profileSection';
import SectionsSection from '../components/sections/sectionsSection';
import ReviewsSection from '../components/sections/reviewsSection';
import Footer from '../components/sections/footer';
import { Dribbble, Linkedin, Mail, Phone } from 'lucide-react';

const HomePage: React.FC = () => {
  return (
    <div className="mx-4 sm:mx-16 md:mx-24 lg:mx-32 xl:mx-48">
      <Header />
      <HeroSection />
      <CompanySection />
      <PortfolioSection />
      <ProfileSection />
      <SectionsSection />
      <ReviewsSection />
      <Footer />
      <div className="flex md:hidden z-50 sticky bottom-0 right-0 w-full pb-4 bg-gradient-to-t from-white to-transparent">
        <div className="flex justify-between items-center w-full bg-white p-2 pr-4 shadow-xl rounded-full border border-gray-100">
          <img src="./profile-photo.webp" alt="Profile" className="w-8 h-8" />
          <div className="flex gap-6">
            <a
              href="https://dribbble.com/saifullahbinkhaki"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Dribbble className="text-[#D85888]" />
            </a>
            <a
              href="https://linkedin.com/in/saifullahbinkhaki"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin className="text-[#2D64BC]" />
            </a>
            <a
              href="mailto:saifbinkhaki@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Mail className="opacity-50" />
            </a>
            <a
              href="https://cal.com/saifbinkhaki/quick-chat-20-minutes"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Phone className="opacity-50" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
